import { useState } from "react";
export const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState(false);

  const onLogin = () => {
    setError(false)
    if (username === "admin" && password === "admin") {
      setIsAuthenticated(true);
      return;
    }
    if (username === "admision" && password === "admision") {
      setIsAuthenticated(true);
      return;
    }
    if (username === "matricula" && password === "matricula") {
      setIsAuthenticated(true);
      return;
    }
    if (username === "academico" && password === "academico") {
      setIsAuthenticated(true);
      return;
    }
   
    setError(true)
  };
  return (
    <div className="flex h-screen w-screen items-center px-96">
      <div className="flex-1"></div>
      {!isAuthenticated && (
        <div className="border p-12 rounded-lg">
          <h1 className="my-2 text-xl">INICIO DE SESSION</h1>
          <div className="flex flex-col">
            <label>Usuario:</label>
            <input
              onChange={(e) => setUsername(e.target.value)}
              className="border p-2 rounded"
            />
          </div>
          <div className="flex flex-col">
            <label className="mt-2">Contraseña:</label>
            <input
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              className="border p-2 rounded"
            />
            <button
              onClick={onLogin}
              className="bg-blue-500 my-5 rounded-lg p-2 text-white"
            >
              INGRESAR
            </button>
          </div>
          {error && <p className="text-red-500">Usuario o clave incorrectos</p>}
        </div>
      )}
      {isAuthenticated && 
      <div>
        <div className="flex flex-col">
          { (username === "admin" || username === "admision") &&
          <a href="https://app.powerbi.com/view?r=eyJrIjoiOWFjZTBhOTgtMjVjNy00OTE4LWI4YTMtZGNhMWZiMjVmZDBjIiwidCI6IjFjMzZmNTA3LWYxOWQtNDc0MC05MTBlLWQ4NjExYjI2NDNhOSJ9 " className="bg-blue-500 m-3 rounded-lg p-3 text-white">1. Dashboard admisión</a>
          }
          { (username === "admin" || username === "matricula") &&
          <a href="https://app.powerbi.com/view?r=eyJrIjoiMTc3MjFkNjEtMDE5Mi00MWY5LTk4NjctYjdlZjAzNGY1ZDkwIiwidCI6IjFjMzZmNTA3LWYxOWQtNDc0MC05MTBlLWQ4NjExYjI2NDNhOSJ9 " className="bg-blue-500 m-3 rounded-lg p-3 text-white">2. Dashboard matrícula</a>
          }
          { (username === "admin" || username === "academico") &&
          <a href="https://app.powerbi.com/view?r=eyJrIjoiY2FmMDQyMzQtN2MwOC00Y2ViLWIwYTQtNzQ2YjdmODgwMDk0IiwidCI6IjFjMzZmNTA3LWYxOWQtNDc0MC05MTBlLWQ4NjExYjI2NDNhOSJ9" className="bg-blue-500 m-3 rounded-lg p-3 text-white">3. Dashboard académico</a>
          }
        </div>
      </div>
      }
      <div className="flex-1"></div>
    </div>
  );
};
