import { Link } from 'react-router-dom'

export const Home = () => {
  return (
    <div className="h-screen flex w-screen items-center justify-center content-center">
      <div className="px-56">
        <h1 className="text-7xl text-gray-500">
          Solución de Inteligencia de Negocios para la toma de decisiones en la
          Gestión Académica
        </h1>
        <div>
          <button className="bg-blue-500 m-4 p-4 rounded-lg text-white">
            <Link to="/login">
            INICIAR SESSION
              </Link>
          </button>
        </div>
      </div>
    </div>
  );
};
